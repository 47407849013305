@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@500&family=Asap:wght@400;500;700&family=Ranga:wght@400;700&family=Rubik:wght@400;500&display=swap');

@font-face {
  font-family: 'HBSerifReg-Regular';
  src: url('~@assets/fonts/HBSerifRegRegular/font.woff2') format('woff2'),
    url('~@assets/fonts/HBSerifRegRegular/font.woff') format('woff');
}

// Blue Oak Theme styles
@import '../colors';
@import '../mixins';

.application {
  @extend .archivo--font;
}

.theme--BlueOakBBQ, .theme--BlueOakHSV {
  ::selection {
    color: white;
    background-color: $color-bloak-piggy-pink;
  }

  h1 {
    @extend .hello-bloomie--font;

    @include font-size(74px);

    line-height: 1.2;
  }
  h2 {
    @extend .hello-bloomie--font;

    @include font-size(36px);

    margin-bottom: 0.5em;
    line-height: 1.2;
  }
  h3 {
    @extend .hello-bloomie--font;

    margin-bottom: 0.5em;
    font-weight: 500;
    line-height: 1.2;
  }
  .header-4 {
    @extend .hello-bloomie--font;

    @include font-size(74px);

    line-height: 0.95 !important;
  }

  .header-3 {
    @extend .hello-bloomie--font;

    line-height: 0.9 !important;
  }

  .header-2 {
    @extend .rubik--font;

    @include font-size(40px, 0.7, 0.75, 0.85);

    line-height: 1.2 !important;
    text-transform: uppercase;
    letter-spacing: 0.074em !important;
  }

  .header-1 {
    @extend .hello-bloomie--font;

    @include font-size(36px, 0.8, 0.8, 0.8);

    line-height: 1.1 !important;
  }

  .hero-text {
    @extend .hello-bloomie--font;

    @include font-size(74px);

    line-height: 0.95;
  }

  .r-headline {
    @extend .archivo--font;

    @include font-size(34px);

    line-height: 1.3 !important;
  }

  .r-title {
    @extend .archivo--font;

    @include font-size(24px, 0.8, 0.8, 0.8);

    line-height: 1.4 !important;
  }

  .btn-base:not(.pa-0):not(.v-btn--icon):not(.v-btn--floating):not(.footer-nav-btn):not(.ui) {
    @extend .rubik--font;

    @include btn-size(1rem, 1rem);

    &.v-btn--small {
      @include btn-size(0.7rem, 0.7rem);

      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    &.v-btn--large {
      @extend .ranga--font;

      @include btn-size(1.7rem);

      font-weight: bold !important;
      line-height: 1.4;
      letter-spacing: 0.043em;
    }
  }
} // .theme--BlueOakBBQ
